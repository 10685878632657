import { gql } from "graphql-tag";
import { LOCALE_FIELDS } from "./Fragments";

export const GET_LOCALES = gql`
    query GetLocales {
        locales {
            ...LocaleFields
        }
    }
    ${LOCALE_FIELDS},
`;

export default { GET_LOCALES };
