import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export interface LocaleInfo {
	locales: Record<any, any>;
}

@Module
export default class LocaleModule extends VuexModule implements LocaleInfo {
	locales = [] as Record<any, any>;

	get getAllLocales(): Record<any, any> {
		return this.locales;
	}

	@Mutation
	[Mutations.ALL_LOCALES](locales: Record<any, any>) {
		this.locales = locales;
	}

	@Action({ rawError: true })
	[Actions.SET_LOCALES](locales: Record<any, any>) {
		this.context.commit(Mutations.ALL_LOCALES, locales);
	}
}
