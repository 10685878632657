import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export interface ActionInfo {
    imagesProps : Record<any, any>
}

@Module
export default class ActionModule extends VuexModule implements ActionInfo {
    imagesProps = [] as Record<any, any>
    
    get getImageProps(): Record<any, any>{
        return this.imagesProps
    }

    @Mutation
    [Mutations.SET_IMAGES](imageProps: Record<any, any>){
        this.imagesProps = imageProps
    }

	@Action({ rawError: true })
    [Actions.TRIGGER_ACTION](data: Record<any, any>, type: string) {
        this.context.commit(Mutations.SET_IMAGES, data.formData)
    }
}
