enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_PAGE_HEADER_ACTION = "setPageHeaderAction",
  SET_PAGE_HISTORY_ACTION = "setPageHistoryAction",
  SET_BUTTON_ACTION = "setButtonAction",
  CREATE_MEDIA = "createMediaAction",
  REMOVE_MEDIA = "removeMediaAction",
  SET_SITES = "getSites",
  SET_LOCALES = "getLocales",
  SET_CONFIG ="getConfigs",
  TRIGGER_ACTION = "triggerAction",
  CLOSED_ACTION = "closeAction" ,
  SEARCH_HANDLER = "searchHandler",
  SET_SUBSCRIPTION = "setSubscription",
  SET_THEME = "setTheme"
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PERMISSIONS = "setPermissions",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_PAGE_HISTORY_MUTATION = "setPageHistory",
  SET_PAGE_HEADER_MUTATION = "setPageHeaderMutation",
  SET_BUTTON_MUTATION = "setActionButtonMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_TOKEN = "setToken",
  ALL_SITES = 'allSites',
  ALL_LOCALES = 'allLocales',
  SET_IMAGES = "setImages",
  ALL_CONFIG = "allConfig",
  SET_SEARCH_DATA ="setSearchData",
  ALL_SUBSCRIPTION= 'allSubscriptions',
  SET_THEME = "setTheme"
}

export { Actions, Mutations };
