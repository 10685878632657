const routes = [
	{
		path: "/system/settings",
		name: "system-settings",
		meta: {
			reqAuth: true,
			gate: "view_setting",
		},
		component: () => import("../settings/views/SystemSettings.vue"),
	},
	{
		path: "/system/users",
		name: "system-users",
		meta: {
			reqAuth: true,
			gate: "view_users_list",
		},
		component: () => import("../users/views/Users.vue"),
	},
	{
		path: "/system/settings/home-screens",
		name: "home-screens",
		meta: {
			reqAuth: true,
			gate: "view_home_screen_list",
		},
		component: () => import("../home-screens/views/HomeScreens.vue"),
	},
	{
		path: "/system/settings/system-settings-status",
		name: "system-settings-status",
		meta: {
			reqAuth: true,
			gate: "view_settings_status",
		},
		component: () => import("../system-settings-status/views/SystemSettingsStatus.vue"),
	},
	{
		path: "/system/settings/taxes",
		name: "taxes",
		meta: {
			reqAuth: true,
			gate: "view_taxes_list",
		},
		component: () => import("../taxes/views/Taxes.vue"),
	},
	{
		path: "/system/settings/languages",
		name: "languages",
		meta: {
			reqAuth: true,
			gate: "view_translation_list",
		},
		component: () => import("../languages/views/Languages.vue"),
	},
	{
		path: "/system/settings/system-configuration",
		name: "system-configurations",
		meta: {
			reqAuth: true,
			gate: "view_system_configuration",
		},
		component: () => import("../system-config/views/SystemConfiguration.vue"),
	},
	{
		path: "/system/settings/sites",
		name: "sites",
		meta: {
			reqAuth: true,
			gate: "view_sites_list",
		},
		component: () => import("../sites/views/Sites.vue"),
	},
	{
		path: "/system/settings/ip-filtering",
		name: "ip-filtering",
		meta: {
			reqAuth: true,
			gate: "view_ip_filtering",
		},
		component: () => import("../ip-filtering/views/IpFiltering.vue"),
	},
	{
		path: "/system/settings/shipping-methods",
		name: "shipping-methods",
		meta: {
			reqAuth: true,
			gate: "view_shipping_methods_list",
		},
		component: () => import("../shipping-methods/views/ShippingMethods.vue"),
	},
	{
		path: "/system/settings/payment-methods",
		name: "payment-methods",
		meta: {
			reqAuth: true,
			gate: "view_payment_methods_list",
		},
		component: () => import("../payment-methods/views/PaymentMethods.vue"),
	},
	{
		path: "/system/settings/shipping-methods/add",
		name: "shipping-methods-add",
		meta: {
			reqAuth: true,
			gate: "add_shipping_method",
		},
		component: () => import("../shipping-methods/views/AddShippingMethod.vue"),
	},
	{
		path: "/system/settings/shipping-methods/edit/:uuid",
		name: "edit-shipping-methods",
		meta: {
			reqAuth: true,
			gate: "edit_shipping_method",
		},
		component: () => import("../shipping-methods/views/EditShippingMethod.vue"),
	},
	{
		path: "/system/settings/discounts",
		name: "discounts",
		meta: {
			reqAuth: true,
			gate: "edit_shipping_method",
		},
		component: () => import("../discounts/views/Discounts.vue"),
	},
	{
		path: "/system/settings/shop-footer",
		name: "shop-footer",
		meta: {
			reqAuth: true,
			gate: "view_shop_footer",
		},
		component: () => import("../shop-footer/views/ShopFooter.vue"),
	},
	// {
	// 	path: "/system/settings/add-discount",
	// 	name: "add-discounts",
	// 	meta: {
	// 		reqAuth: true,
	// 		gate: 'edit_shipping_method'
	// 	},
	// 	component: () => import("../discounts/components/AddDiscount.vue")
	// },
];

export default routes;
