import { Axios } from './index';
import i18n from '../plugins/i18n';

const SetLocaleMessage = async (locale: string) => {
    const availableLocales = i18n.global.availableLocales
    const ClearLocaleCache = (locales, length) => {
        if (length >= 0 && locales[length-1] != locale)
        {
            i18n.global.setLocaleMessage(locales[length-1], { })
            ClearLocaleCache(locales,length-1);
        }
    }
    ClearLocaleCache(availableLocales,availableLocales.length)
    i18n.global.fallbackLocale.value = locale
    i18n.global.locale.value = locale
    const resp = await Axios.get(`/locales/translations/${locale}`)
    const message = resp.data.message
    i18n.global.setLocaleMessage(locale, { message })
}

export default { SetLocaleMessage }