import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "../enums/StoreEnums";

export interface PageHistory {
    pageData: object;
}

@Module
export default class PageHistoryModule extends VuexModule implements PageHistory {
    pageData = {} as Record<any, any>

    /**
     * get previous page data(search key, filter, page number)
     * @return object
     */
    get getPageHistory(): object {
        return this.pageData
    }

    @Mutation
    [Mutations.SET_PAGE_HISTORY_MUTATION](payload) {
        this.pageData = payload
    }

    @Action
    [Actions.SET_PAGE_HISTORY_ACTION](payload) {
        this.context.commit(Mutations.SET_PAGE_HISTORY_MUTATION, payload)
    }
}