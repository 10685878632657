
const routes = [
    {
        path:"/subscription",
        name:"subscription",
        meta : {
            reqAuth: true,
            gate: 'view_subscription_list'
        },
        component: () => import("../views/Subscription.vue")
    },
    {
        path:"/subscription/details/:uuid",
        name:"subscription_detail",
        meta: {
            reqAuth: true,
            gate: 'view_subscription_details'
        },
        component: () => import("../views/SubscriptionDetails.vue")
    }
];

export default routes;