import Notify from "@/core/services/Notify";
import Apollo from "@/core/services/ApolloService";
import Axios from "./Http";
import Acl from "./Acl"

const requestInterceptor = (config: any) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.requestorigin = 'ShopAdmin';
    }
  
    const A_ID = localStorage.getItem("A_ID");
    if (A_ID) {
      config.headers.A_ID = A_ID;
      config.headers.requestorigin = 'ShopAdmin';
    }
  
    return config;
  };

    const  SSOAxios = Axios.create({
    baseURL: process.env.VUE_APP_GEP_AUTH,
  });
  
  SSOAxios.interceptors.request.use(requestInterceptor, err => Promise.reject(err));


export { Notify, Apollo, Axios, Acl, SSOAxios};
