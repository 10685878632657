const routes = [
    {
        path: '/customers/order-report',
        name: 'customers_order_report',
        meta: {
            reqAuth: true,
            gate: 'view_customers_list'
        },
        component: () => import('../views/CustomersOrderList.vue')
    }
];

export default routes;
