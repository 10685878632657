import store from "@/store";

const error = (error: any) => {
  let message = "";
  if (error?.response) {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      store.getters.appInstance.$router.push({ name: "login" });
    }
    message = error.response.data.message;
  } else {
    message = error.toString();
  }
  store.getters.appInstance?.$notify.error({
    title: "Error",
    message: message,
  });
};

const success = (message: string) => {
  store.getters.appInstance.$notify.success({
    title: "Success",
    message: message,  
  });
};

const info = (message: string) => {
  store.getters.appInstance.$notify.info({
    title: "info",
    message: message,
  });
};

export default { error, success, info };
 