import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule"
import BodyModule from "@/store/modules/BodyModule"
import ToolbarModule from "@/store/modules/ToolbarModule"
import ConfigModule from "@/store/modules/ConfigModule"
import SiteModule from '@/store/modules/SiteModule'
import MediaModule from "@/store/modules/MediaModule"
import ActionModule from '@/store/modules/ActionModule'
import SystemConfigModule from '@/store/modules/SystemConfigModule'
import SearchModule from "@/store/modules/SearchModule"
import SubscriptionModule from "@/store/modules/SubscriptionModule"
import PageHistoryModule from "@/store/modules/PageHistoryModule";
import LocaleModule from '@/store/modules/LocaleModule'

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    ToolbarModule,
    ConfigModule,
    SiteModule,
    MediaModule,
    ActionModule,
    SearchModule,
    SystemConfigModule,
    SubscriptionModule,
    PageHistoryModule,
    LocaleModule
  }
});

export default store;
