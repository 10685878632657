import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import Axios from "@/core/services/Http";
import i18nProvider from "@/core/services/i18nProvider";
import { SSOAxios } from "@/core/services";
import axios from "axios";

export interface User {
	uuid: string;
	name: string;
	email: string;
	password: string;
	token: string;
}

export interface UserAuthInfo {
	errors: Array<string>;
	user: User;
	API_URL: string;
	permissions: any;
	instance: any;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
	errors = [];
	user = {} as User;
	API_URL = "/auth";
	instance = {};
	permissions = {};

	get getUser(): User {
		return this.user;
	}

	get getPermissions(): Record<any, any> {
		return this.permissions;
	}

	get appInstance(): Record<any, any> {
		return this.instance;
	}

	@Mutation
	SET_USER(user: any) {
		this.user = user;
	}

	@Mutation
	SET_PERMISSIONS(permissions: any) {
		this.permissions = permissions;
	}

	@Mutation
	GATE_ROUTE(app: Record<any, any>) {
		this.instance = app.config.globalProperties;
	}

	@Action({ rawError: true })
	async loginUser(credentials: object) {
		const resp = await SSOAxios.post(`/api/api-handshake`, credentials);
		localStorage.setItem("token", resp.data.token);
		return true;
	}

	@Action({ rawError: true })
	async isEmailExist(credentials: string) {
		const resp = await Axios.post(`${this.API_URL}/email_exist`, credentials);
		return resp;
	}

	@Action({ rawError: true })
	async abilities(app: Record<any, any>) {
		this.context.commit("GATE_ROUTE", app);
		let app_url = '';
		// if (localStorage.getItem('authorize_state'))
		// {
		// 	app_url = `${this.API_URL}/info?state_token=${localStorage.getItem('authorize_state')}&state_request=Admin`;
		// }
		// else
		// {
			app_url = `${this.API_URL}/info`;
		// }
		const resp= await Axios.get(app_url);
		await i18nProvider.SetLocaleMessage(resp.data.info.locale)
		await app.config.globalProperties.$updatePermissions(resp.data.abilities);
		await app.config.globalProperties.$updateRoles(resp.data.roles);
		this.context.commit("SET_USER", resp.data.info);
		this.context.commit("SET_PERMISSIONS", resp.data.abilities);
		return resp;
	}

	@Action({ rawError: true })
	async logout() {
		const response = await Axios.get(`/auth/logout`,{
			headers :{
				Authorization: 'Bearer ' + localStorage.getItem("token")
			}
		});
		const session_timestamp = {
			'session_duration' : response?.data?.session_duration,
			'login_date' : response?.data?.login_date
		}
		localStorage.setItem("session-timestamp",JSON.stringify(session_timestamp));
		
		if (!localStorage.getItem('A_ID') || localStorage.getItem('A_ID') == undefined)
		{
			const resp = await Axios.post('/session/api-handshake', {locale: 'da', session_id: localStorage.getItem('A_ID')});
			localStorage.setItem('A_ID',resp.data);
		}
		localStorage.removeItem("token");
		localStorage.removeItem('code');
		localStorage.removeItem('state');
		window.location.href = `${process.env.VUE_APP_GEP_AUTH}/sso-logout?continue=${process.env.VUE_APP_BASE_URL}`
		return true;
	}
}
