const routes = [
  {
    path: "/login",
    name: "login",
    meta: {
            reqGuest: true,
          },
    component: () => import("../views/Login.vue")
  },
  {
    path: "/authorization",
    name: "authorization",
    meta: {
            reqGuest: true,
          },
    component: () => import("../views/Authorization.vue")
  },
  // {
  //   path: "/forgot-password",
  //   name: "forgot-password",
  //   meta: {
  //           reqGuest: false,
  //         },
  //   component: () => import("../views/ForgotPassword.vue")
  // },
  // {
  //   path: "/reset-password/:token",
  //   name: "reset-password",
  //   meta: {
  //           reqGuest: false,
  //         },
  //   component: () => import("../views/ResetPassword.vue")
  // }
];

export default routes;