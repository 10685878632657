import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { authRoutes } from "@/modules/auth";
import { systemRoutes } from "@/modules/system";
import { catalogRoutes } from "@/modules/catalog";
import { customerRoutes } from "@/modules/customer"
import dashboardRoutes from "@/modules/dashboard/routes";
import { orderRoutes } from "@/modules/order";
import {permissionRoute} from "@/modules/permission";
import { economicRoute } from "@/modules/economic";
import { subscriptionRoute } from "@/modules/subscription";
import { failedJobsRoute } from "@/modules/failed-jobs";
import { customersReportRoute } from "@/modules/reports";

import store from "@/store";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		redirect: "/dashboard",
		component: () => import("@/layout/Layout.vue"),
		children: [
			...dashboardRoutes,
			...systemRoutes,
			...catalogRoutes,
			...customerRoutes,
			...orderRoutes,
			...permissionRoute,
			...economicRoute,
			...subscriptionRoute,
            ...failedJobsRoute,
			...customersReportRoute,

			{
				path: "/builder",
				name: "builder",
				component: () => import("@/views/Builder.vue")
			}
		]
	},
	...authRoutes
];

const router = createRouter({
	history: createWebHistory(),
	routes
});

router.beforeEach((to, from, next) => {
	const token = localStorage.getItem('token')
	const permission: any = store.getters.appInstance
	if (token) {
		if (to.meta.reqAuth && token && permission.$can(to.meta.gate)) {
			next()
			return
		} else {
			next({ name: "dashboard" })
		}
	}

	if (to.meta.reqGuest) {
		next()
		return
	}

	next({ name: 'login' })

});

export default router;
