import { gql } from "graphql-tag";

export const SITE_FIELDS = gql`
  fragment SiteFields on Site {
		id
		name
		type
		path
		country_id
		status
		prices_with_tax
		economic_account_id
	}
`;

export default { SITE_FIELDS };
