import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions } from "@/store/enums/StoreEnums";

export interface Search {
    searchData : Record<any, any>
}

@Module
export default class SearchModule extends VuexModule implements Search {
    searchData = [] as Record<any, any>

    get getSearchData(): Record<any, any> {
		return this.searchData;
	}
    
    @Mutation
	SET_SEARCH_DATA(search: any) {
		this.searchData = search;
	}

	@Action({ rawError: true })
    async [Actions.SEARCH_HANDLER](data: Record<any, any>) {
        this.context.commit("SET_SEARCH_DATA",data);
    }
}
