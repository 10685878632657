
const routes = [
    {
      path:"/permission/groups",
      name:"permission_groups",
      meta : {
              reqAuth: true,
              gate: 'view_permission_group_list'
            },
      component: () => import("../permission-groups/views/PermissionGroup.vue")
    },
  ];
  
  export default routes;