import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { boolean } from "yup";

export interface ConfigInfo {
    configs : Record<any, any>
}

@Module
export default class SystemConfigModule extends VuexModule implements ConfigInfo {
    configs = [] as Record<any, any>

    get getAllConfigs(): Record<any, any>{
        return this.configs
    }

    @Mutation
    [Mutations.ALL_CONFIG](configs: Record<any, any>){
        this.configs = configs
    }

	@Action({ rawError: true })
    [Actions.SET_CONFIG](configs: Record<any, any>) {
        this.context.commit(Mutations.ALL_CONFIG, configs)
    }
}
