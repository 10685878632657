import { gql } from "graphql-tag";

export const COUNTRY_FIELDS = gql`
  fragment CountryFields on Country {
    id
    name
    locale
    iso_code_3
    currency
  }
`;

export default { COUNTRY_FIELDS };
