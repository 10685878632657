import { Apollo } from "@/core/services";
import { GET_COUNTRIES } from "../common/countries/graphql/Queries";
import { GET_CONFIG } from "../common/configuration/graphql/Queries"
import { GET_LOCALES } from "../common/locales/graphql/Queries"
import { GET_SITES} from './sites/graphql/Queries'
import { setConfigs, setSites, setLocales } from "./siteHelper"

Apollo.watchQuery({
    query: GET_COUNTRIES,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    context: {
        uri: process.env.VUE_APP_GEP_COMMON,
    },
}).subscribe({})

Apollo.watchQuery({
    query: GET_CONFIG,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "network-only",
    context: {
        uri: process.env.VUE_APP_GEP_COMMON,
    },
}).subscribe(({ data }) => {
    setConfigs(data)
})

Apollo.watchQuery({
    query: GET_LOCALES,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    context: {
        uri: process.env.VUE_APP_GEP_COMMON,
    },
}).subscribe(({ data }) => {
    setLocales(data)
})

Apollo.watchQuery({
    query: GET_SITES,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    context: {
        uri: process.env.VUE_APP_GEP_COMMON,
    },
}).subscribe(({ data}) => {
	setSites(data)
});