import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export interface SubscriptionInfo {
    subscription : Record<any, any>
}

@Module
export default class SubscriptionModule extends VuexModule implements SubscriptionInfo {
    subscription = [] as Record<any, any>
    
    get getAllSubscription(): Record<any, any>{
        return this.subscription
    }

    @Mutation
    [Mutations.ALL_SUBSCRIPTION](subscription: Record<any, any>){
        this.subscription = subscription
    }

	@Action({ rawError: true })
    [Actions.SET_SUBSCRIPTION](subscription: Record<any, any>) {
        this.context.commit(Mutations.ALL_SUBSCRIPTION, subscription)
    }
}
