import { ApolloClient, ApolloLink, from } from "@apollo/client/core";
import { InMemoryCache } from "@apollo/client/cache";
import { onError } from "@apollo/client/link/error";
import { Notify } from "./index";
import { createUploadLink } from 'apollo-upload-client'
import i18n from "@/core/plugins/i18n";
import store from "@/store";

const httpLink = new createUploadLink({
	uri: process.env.VUE_APP_GEP_DEFAULT
});

const additiveLink = from([
	new ApolloLink((operation, forward) => {
		const token = localStorage.getItem("token");
		operation.setContext(({ headers }) => ({
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : null
			}
		}));
		return forward(operation);
	}),
	httpLink
]);

const throughError = (e, type) => {
	if (type === "gql") {
		if (e.validation) {
			if (e.validation["user.email"] || e.validation["customer.email"]) {
				Notify.error(i18n.global.t("message.EMAIL_ALREADY_EXISTS"));
			} else if (e.validation["keyData.key"]) {
				Notify.error(i18n.global.t("message.KEY_ALREADY_EXISTS"));
			} else {
				Notify.error(i18n.global.t("message.FILL_ALL_MISSING_FIELDS"));

			}
		} else {
			
			// const custom_message = e?.custom_message

			if (process.env.VUE_APP_DEBUG == "true") {
				// if(e.custom_message != '') {
				// 	Notify.info(
				// 		i18n.global.t(e.custom_message)
				// 	);
				// } else {
				// 	Notify.error(e.message);
				// }
				Notify.error(e.message);
			} else {
				Notify.error(i18n.global.t('message.SOMETHING_WENT_WRONG'));
			}
		}
	} else if (type === "network") {
		if (process.env.VUE_APP_DEBUG == "true") {
			Notify.error(`Network error: ${e.message}`);
		} else {
			Notify.error(i18n.global.t('message.SOMETHING_WENT_WRONG'));
		}
	}
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) throughError(graphQLErrors[0], "gql");
	if (networkError)
	{
		process.env.VUE_APP_DEBUG == "true"
			? Notify.error(`Network error: ${networkError.message}`)
			: Notify.error(i18n.global.t('message.SOMETHING_WENT_WRONG'));

		if (networkError?.message.includes('401'))
		{
			localStorage.removeItem('token');
			store.getters.appInstance.$router.push({ name: "login" });
		}
	}
});

const Apollo = new ApolloClient({
	link: from([errorLink, additiveLink]),
	cache: new InMemoryCache()
});

export default Apollo;
