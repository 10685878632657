import { gql } from "graphql-tag";

export const CREATE_MEDIA = gql`
  mutation CreateMedia($images: Upload) {
    create_media(images: $images) 
  }
`;

export const DELETE_MEDIA = gql`
mutation DeleteMedia($id: Int!) {
  delete_media(id: $id) 
}
`;

export default { CREATE_MEDIA, DELETE_MEDIA }