
const routes = [
    {
      path:"/orders",
      name:"order_lists",
      meta : {
              reqAuth: true,
              gate: 'view_order_list'
            },
      component: () => import("../views/Orders.vue")
    },
    {
      path:"/order/details/:uuid",
      name:"order_details",
      meta : {
              reqAuth: true,
              gate: 'view_order_detail'
            },
      component: () => import("../views/OrderDetails.vue")
    },
  ];
  
  export default routes;