import { gql } from "graphql-tag";
import { COUNTRY_FIELDS } from "./Fragments";

export const GET_COUNTRIES = gql`
    query GetCountries {
        countries {
            ...CountryFields
        }
    }
    ${COUNTRY_FIELDS},
`;

export default { GET_COUNTRIES };
