const routes = [
	{
		path: "/economic",
		name: "economic",
		meta: {
			reqAuth: true,
			gate: 'view_economic'
		},
		component: () => import("../views/Economic.vue")
	},
]

export default routes