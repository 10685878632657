import { gql } from "graphql-tag";
import { SITE_FIELDS } from "./Fragments";

export const GET_SITES = gql`
    query GetSites {
        sites {
            ...SiteFields
        }
    }
    ${SITE_FIELDS},
`;

export const SEARCH_SITES = gql`
    query SearchSites($search_key: String, $type:String , $date_from:String , $date_to:String) {
        search_sites(search_key:$search_key, type:$type , date_from:$date_from , date_to:$date_to){
            ...SiteFields
        }
    }
    ${SITE_FIELDS},
`;

export default { GET_SITES,SEARCH_SITES };
