import { App } from "vue";

const Acl = {
	install: function (app: App) {
		let abilities: any = {};
		let newRoles: any = [];
		app.config.globalProperties.$updatePermissions = function (permissions: []) {
			abilities = permissions;
			return true;
		};

		app.config.globalProperties.$can = function (ability: any) {
			return abilities[ability];
		};

		app.config.globalProperties.$updateRoles = function (roles: []) {
			newRoles = roles;
			return true;
		};

		app.config.globalProperties.$hasRole = function (role: any) {
			return newRoles[role];
		};
	}
};

export default Acl;