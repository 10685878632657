import { gql } from "graphql-tag";

export const LOCALE_FIELDS = gql`
  fragment LocaleFields on Locales {
		id
		name
		locale
	}
`;

export default { LOCALE_FIELDS };
