const routes = [
    {
        path: '/customer/lists',
        name: 'customers_list',
        meta: {
            reqAuth: true,
            gate: 'view_customers_list'
        },
        component: () => import('../customers/views/Customers.vue')
    },
    {
        path: '/customer/add',
        name: 'add_customer',
        meta: {
            reqAuth: true,
            gate: 'add_customer'
        },
        component: () => import('../customers/views/AddCustomer.vue')
    },
    {
        path: '/customer/edit/:uuid',
        name: 'edit_customer',
        meta: {
            reqAuth: true,
            gate: 'edit_customer'
        },
        component: () => import('../customers/views/EditCustomer.vue')
    },
    {
        path: '/customer/assignment/:uuid',
        name: 'assignment_price_group',
        meta: {
            reqAuth: true,
            gate: 'price_group_assignment'
        },
        component: () => import('../price-groups/views/AssignmentPriceGroup.vue')
    },
    {
        path: '/customer/detail/:uuid',
        name: 'customer_detail',
        meta: {
            reqAuth: true,
            gate: 'view_addresses_list'
        },
        component: () => import('../customers/views/CustomerDetail.vue')
    },
    {
        path: '/customer/price-groups',
        name: 'price_group',
        meta: {
            reqAuth: true,
            gate: 'view_price_groups_list'
        },
        component: () => import('../price-groups/views/PriceGroup.vue')
    },
    {
        path: '/customer/customer-groups',
        name: 'customer_groups',
        meta: {
            reqAuth: true,
            gate: 'view_customer_groups_list'
        },
        component: () => import('../customer-groups/views/CustomerGroup.vue')
    }
];

export default routes;
