import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_GEP_BASE_URL;

const requestInterceptor = (config: any) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  const A_ID = localStorage.getItem("A_ID");
  if (A_ID) {
    config.headers.A_ID = A_ID;
  }

  return config;
};

axios.interceptors.request.use(requestInterceptor, err => Promise.reject(err));

export default axios;
