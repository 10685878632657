import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export interface SiteInfo {
    sites : Record<any, any>
}

@Module
export default class SiteModule extends VuexModule implements SiteInfo {
    sites = [] as Record<any, any>
    
    get getAllSites(): Record<any, any>{
        return this.sites
    }

    @Mutation
    [Mutations.ALL_SITES](sites: Record<any, any>){
        this.sites = sites
    }

	@Action({ rawError: true })
    [Actions.SET_SITES](sites: Record<any, any>) {
        this.context.commit(Mutations.ALL_SITES, sites)
    }
}
