const routes = [
	{
		path: "/jobs-monitor",
		name: "jobs-monitor",
		meta: {
			reqAuth: true,
			gate: "view_failed_jobs_list",
		},
		component: () => import("../views/JobsMonitor.vue"),
	},
	{
		path: "/jobs-monitor/failed-jobs",
		name: "failed-jobs",
		meta: {
			reqAuth: true,
			gate: "view_failed_jobs_list",
		},
		component: () => import("../views/FailedJobs.vue"),
	},
];

export default routes;
