import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";


interface Button {
  title: string;
  icon: string;
  class: string;
  openModal: boolean;
  toggleModal: boolean;
}

interface PageAction {
  action: string;
  params: string;
}

interface ActionButton {
  button: Button;
  pageAction: PageAction;
  isAction: boolean;
  buttonAction: string;
}

interface StoreInfo {
  actionButton: ActionButton;
  pageHeader: PageHeader;
}

interface BreadCrumb {
    name: string,
    link: string
}

interface PageHeader {
    title: string;
    actionButton: ActionButton;
    breadCrumb: BreadCrumb;
} 

@Module
export default class ToolbarModule extends VuexModule implements StoreInfo {
  actionButton = {} as ActionButton;
  pageHeader = {} as PageHeader;
  
  /**
    * Get Current page headers
    * @return object
   */
  get getPageHeader(): object {
    return this.pageHeader;
  } 
  
  @Mutation
  [Mutations.SET_PAGE_HEADER_MUTATION](payload) {
    this.pageHeader = payload
  }
  
  @Action
  [Actions.SET_PAGE_HEADER_ACTION](payload) {
    this.context.commit(Mutations.SET_PAGE_HEADER_MUTATION,payload);
  }
}
