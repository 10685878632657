import { gql } from "graphql-tag";
import { CONFIG_FIELDS } from "./Fragments";

export const GET_CONFIG = gql`
    query GetConfiguration($is_default: Int) {
        system_configuration(is_default: $is_default) {
            ...ConfigurationFields
        }
    }
    ${CONFIG_FIELDS},
`;

export default { GET_CONFIG };
