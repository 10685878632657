import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

export const setSites = (data: Record <any, any>): void => {
    store.dispatch(Actions.SET_SITES, {
        data:data
    })
};

export const setConfigs = (data : Record<any, any>) : void => {
    store.dispatch(Actions.SET_CONFIG, {
        data:data
    })
};

export const setLocales = (data: Record <any, any>): void => {
    store.dispatch(Actions.SET_LOCALES, {
        data:data
    })
};