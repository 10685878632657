const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
            reqAuth: true,
            gate: 'view_dashboard'
          },
    component: () => import("./views/Dashboard.vue")
  }
];

export default routes;
