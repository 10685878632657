import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions } from '@/store/enums/StoreEnums';
import { CREATE_MEDIA, DELETE_MEDIA } from '@/modules/common/media/Mutations';
import { Apollo } from '@/core/services';

export interface Media {
    media: Record<any, any>;
}

@Module
export default class MediaModule extends VuexModule implements Media {
    media = [] as Record<any, any>;

    @Action({ rawError: true })
    async [Actions.CREATE_MEDIA](files: Record<any, any>) {
        const resp = await Apollo.mutate({
            mutation: CREATE_MEDIA,
            variables: { images: files },
            context: {
                uri: process.env.VUE_APP_GEP_COMMON
            }
        });
        return resp.data?.create_media;
    }

    @Action({ rawError: true })
    async [Actions.REMOVE_MEDIA](id: number) {
        const resp = await Apollo.mutate({
            mutation: DELETE_MEDIA,
            variables: { id: id },
            context: {
                uri: process.env.VUE_APP_GEP_COMMON
            }
        });
        return resp.data?.delete_media;
    }
}
