import { gql } from "graphql-tag";

export const CONFIG_FIELDS = gql`
  fragment ConfigurationFields on SystemConfiguration {
		id
		name
		is_default
		country {
			id
			locale
			name
			currency
		}
		site {
		  id
		  name
		}
  	}
`;

export default { CONFIG_FIELDS };
